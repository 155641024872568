// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Asset Logo Wrapper */
.asset-logo-wrapper {
  position: relative;
  display: inline-block;
}

/* Underlay Circle for the Logo */
.underlay-circle {
  position: absolute;
  width: 59px;
  height: 59px;
  top: -3px;
  left: -3px;
  border-radius: 50%;
  background: var(--darkBlue);
  z-index: 0;
}

/* Removed the animation and gradient */
.underlay-circle:before,
.underlay-circle:after {
  content: " ";
  position: absolute;
  inset: 8px;
  border-radius: inherit;
}

/* Asset Logo */
.asset-logo {
  position: relative;
  z-index: 1;
  width: 53px;
  height: 53px;
  margin-right: 25px;
  border-radius: 50%;
}

.underlay-circle:before {
  background: linear-gradient(45deg, transparent, transparent 40%, yellow);
  filter: blur(32px);
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
    filter: hue-rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/logo.css"],"names":[],"mappings":"AAAA,uBAAuB;AACvB;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA,iCAAiC;AACjC;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,SAAS;EACT,UAAU;EACV,kBAAkB;EAClB,2BAA2B;EAC3B,UAAU;AACZ;;AAEA,uCAAuC;AACvC;;EAEE,YAAY;EACZ,kBAAkB;EAClB,UAAU;EACV,sBAAsB;AACxB;;AAEA,eAAe;AACf;EACE,kBAAkB;EAClB,UAAU;EACV,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,wEAAwE;EACxE,kBAAkB;AACpB;;AAEA;EACE;IACE,yBAAyB;IACzB,0BAA0B;EAC5B;AACF","sourcesContent":["/* Asset Logo Wrapper */\n.asset-logo-wrapper {\n  position: relative;\n  display: inline-block;\n}\n\n/* Underlay Circle for the Logo */\n.underlay-circle {\n  position: absolute;\n  width: 59px;\n  height: 59px;\n  top: -3px;\n  left: -3px;\n  border-radius: 50%;\n  background: var(--darkBlue);\n  z-index: 0;\n}\n\n/* Removed the animation and gradient */\n.underlay-circle:before,\n.underlay-circle:after {\n  content: \" \";\n  position: absolute;\n  inset: 8px;\n  border-radius: inherit;\n}\n\n/* Asset Logo */\n.asset-logo {\n  position: relative;\n  z-index: 1;\n  width: 53px;\n  height: 53px;\n  margin-right: 25px;\n  border-radius: 50%;\n}\n\n.underlay-circle:before {\n  background: linear-gradient(45deg, transparent, transparent 40%, yellow);\n  filter: blur(32px);\n}\n\n@keyframes rotate {\n  100% {\n    transform: rotate(360deg);\n    filter: hue-rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
