// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.similar-project {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  justify-content: flex-start;
  padding-left: 0;
  margin-left: 0;
}

.similar-project-logo {
  width: 40px;
  height: 40px;
  margin-right: 8px;
  border-radius: 50%;
  border: 2px solid var(--darkBlue);
}

.similar-project div {
  flex: 1 1;
  font-size: 14px;
  margin-left: 0;
  color: var(--textColor);
}

#similar-projects {
  padding: 15px;
  padding-top: 15px;
  height: max-content;
  background: var(--background);
}
`, "",{"version":3,"sources":["webpack://./src/styles/similar-projects.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,2BAA2B;EAC3B,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,iCAAiC;AACnC;;AAEA;EACE,SAAO;EACP,eAAe;EACf,cAAc;EACd,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,mBAAmB;EACnB,6BAA6B;AAC/B","sourcesContent":[".similar-project {\n  display: flex;\n  align-items: center;\n  margin-bottom: 8px;\n  justify-content: flex-start;\n  padding-left: 0;\n  margin-left: 0;\n}\n\n.similar-project-logo {\n  width: 40px;\n  height: 40px;\n  margin-right: 8px;\n  border-radius: 50%;\n  border: 2px solid var(--darkBlue);\n}\n\n.similar-project div {\n  flex: 1;\n  font-size: 14px;\n  margin-left: 0;\n  color: var(--textColor);\n}\n\n#similar-projects {\n  padding: 15px;\n  padding-top: 15px;\n  height: max-content;\n  background: var(--background);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
