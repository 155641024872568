import React from 'react';
import TradingViewWidget from '../TradingViewWidget';
import '../styles/common.css';

const TradingViewTab = ({ symbol }) => {
  return (
    <div id="tradingview">
      <TradingViewWidget ticker={symbol} />
    </div>
  );
};

export default TradingViewTab;
