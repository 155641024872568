import React from 'react';
import '../styles/skeleton.css';

export const SkeletonCircle = ({ size = 'medium' }) => (
  <div className={`skeleton-circle skeleton-circle-${size}`}></div>
);

export const SkeletonText = ({ width = '100%', height = '1em' }) => (
  <div className="skeleton-text" style={{ width, height }}></div>
);

export const SkeletonBox = ({ height = '100px' }) => (
  <div className="skeleton-box" style={{ height }}></div>
);

export const ProjectSkeleton = () => (
  <div className="content-area">
    <div className="sticky-header">
      <div className="project-header-skeleton">
        <div className="project-logo-skeleton">
          <SkeletonCircle size="large" />
        </div>
        <div className="project-info-skeleton">
          <SkeletonText width="200px" height="1.5em" />
          <SkeletonText width="300px" />
          <div className="social-media-skeleton">
            <SkeletonText width="30px" />
            <SkeletonText width="30px" />
            <SkeletonText width="30px" />
          </div>
        </div>
      </div>
    </div>
    <div className="project-content-skeleton">
      <div className="content-section">
        <SkeletonText width="150px" height="1.2em" />
        <div className="stats-skeleton">
          <SkeletonBox height="80px" />
          <SkeletonBox height="80px" />
        </div>
      </div>
      <div className="content-section">
        <SkeletonText width="150px" height="1.2em" />
        <SkeletonText />
        <SkeletonText width="90%" />
        <SkeletonText width="95%" />
      </div>
    </div>
  </div>
);

export const PeopleSkeleton = () => (
  <div className="content-area">
    <div className="sticky-header compact">
      <div className="person-header-skeleton">
        <div className="person-header-content">
          <div className="person-logo-skeleton">
            <SkeletonCircle size="medium" />
          </div>
          <div className="person-info-skeleton">
            <div className="person-header-top">
              <SkeletonText width="180px" height="1.5em" />
              <SkeletonText width="100px" />
            </div>
            <SkeletonText width="250px" />
            <div className="social-media-skeleton">
              <SkeletonText width="24px" />
              <SkeletonText width="24px" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="person-content-skeleton">
      <div className="content-section">
        <SkeletonText width="120px" height="1.2em" />
        <SkeletonText />
        <SkeletonText width="95%" />
        <SkeletonText width="90%" />
      </div>
      <div className="content-section">
        <SkeletonText width="100px" height="1.2em" />
        <div className="stats-skeleton">
          <SkeletonBox height="80px" />
          <SkeletonBox height="80px" />
        </div>
      </div>
      <div className="content-section">
        <SkeletonText width="120px" height="1.2em" />
        <div className="stats-skeleton">
          <SkeletonBox height="80px" />
          <SkeletonBox height="80px" />
        </div>
      </div>
    </div>
  </div>
);
