import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FaLinkedin, FaXTwitter } from 'react-icons/fa6';
import axios from 'axios';
import Header from '../components/Header';
import { PeopleSkeleton } from '../components/Skeleton';
import InvestmentsTab from '../components/org/InvestmentsTab';
import OrgTeamTab from '../components/org/OrgTeamTab';
import OrgSocialTab from '../components/org/OrgSocialTab';
import '../styles/common.css';
import '../styles/people.css';

const OrgPage = () => {
  const { orgId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [orgData, setOrgData] = useState(null);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('Investments');
  const [colorScheme, setColorScheme] = useState(() => {
    return localStorage.getItem('theme') || 'light';
  });

  useEffect(() => {
    document.body.setAttribute('data-theme', colorScheme);
  }, [colorScheme]);

  const toggleTheme = () => {
    const newTheme = colorScheme === 'light' ? 'dark' : 'light';
    setColorScheme(newTheme);
    localStorage.setItem('theme', newTheme);
    document.body.setAttribute('data-theme', newTheme);
  };

  useEffect(() => {
    const fetchOrgData = async () => {
      try {
        const response = await axios.post('/api/org', { orgId: parseInt(orgId) });
        setOrgData(response.data.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to load organization data');
        setLoading(false);
      }
    };

    fetchOrgData();
  }, [orgId]);

  if (loading) {
    return (
      <div className="app-container">
        <Header colorScheme={colorScheme} toggleTheme={toggleTheme} showSearch={true} />
        <div className="content-container">
          <div className="content-wrapper">
            <div className="scroll-container">
              <PeopleSkeleton />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="app-container">
        <Header colorScheme={colorScheme} toggleTheme={toggleTheme} showSearch={true} />
        <div className="error-container">
          <p>{error}</p>
          <button onClick={() => window.location.reload()} className="retry-button">
            Retry
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className={`app-container ${colorScheme}`}>
      <Header colorScheme={colorScheme} toggleTheme={toggleTheme} showSearch={true} />
      <div className="content-container">
        <div className="content-wrapper">
          <div className="scroll-container">
            <div className="content-area">
              <div className="sticky-header compact">
                <div className="person-header no-rounded-corner">
                  <div className="person-header-content">
                    <div className="person-logo-wrapper">
                      <div className="underlay-circle"></div>
                      <img src={orgData.logo} alt={orgData.org_name} className="person-logo" />
                    </div>
                    <div className="person-info">
                      <div className="person-header-top">
                        <h2>{orgData.org_name}</h2>
                        <button onClick={() => navigate(-1)} className="back-button">
                          Back to project
                        </button>
                      </div>
                      {orgData.description && <p className="one-liner">{orgData.description}</p>}
                      <div className="social-media">
                        {orgData.social_media?.linkedin && (
                          <a href={orgData.social_media.linkedin} target="_blank" rel="noopener noreferrer" className="social-icon">
                            <FaLinkedin />
                          </a>
                        )}
                        {orgData.social_media?.X && (
                          <a href={orgData.social_media.X} target="_blank" rel="noopener noreferrer" className="social-icon">
                            <FaXTwitter />
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tabs-container">
                  <div className="tabs">
                    <button
                      className={`tab-button ${activeTab === 'Investments' ? 'active' : ''}`}
                      onClick={() => setActiveTab('Investments')}
                    >
                      Investments
                    </button>
                    <button
                      className={`tab-button ${activeTab === 'Team' ? 'active' : ''}`}
                      onClick={() => setActiveTab('Team')}
                    >
                      Team
                    </button>
                    <button
                      className={`tab-button ${activeTab === 'Social' ? 'active' : ''}`}
                      onClick={() => setActiveTab('Social')}
                    >
                      Social
                    </button>
                  </div>
                </div>
              </div>

              <div className="person-content">
                {activeTab === 'Investments' && orgData.investments && (
                  <InvestmentsTab investments={orgData.investments} />
                )}
                {activeTab === 'Team' && orgData.team_members && (
                  <OrgTeamTab teamMembers={orgData.team_members} />
                )}
                {activeTab === 'Social' && (
                  <OrgSocialTab orgData={orgData} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrgPage;
