// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Toast notification */
.toast-notification {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 14px;
  z-index: 1000;
  animation: fadeInOut 3s ease;
}

@keyframes fadeInOut {
  0% { opacity: 0; transform: translate(-50%, 20px); }
  15% { opacity: 1; transform: translate(-50%, 0); }
  85% { opacity: 1; transform: translate(-50%, 0); }
  100% { opacity: 0; transform: translate(-50%, -20px); }
}
`, "",{"version":3,"sources":["webpack://./src/styles/notifications.css"],"names":[],"mappings":"AAAA,uBAAuB;AACvB;EACE,eAAe;EACf,YAAY;EACZ,SAAS;EACT,2BAA2B;EAC3B,8BAA8B;EAC9B,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;EACf,aAAa;EACb,4BAA4B;AAC9B;;AAEA;EACE,KAAK,UAAU,EAAE,gCAAgC,EAAE;EACnD,MAAM,UAAU,EAAE,6BAA6B,EAAE;EACjD,MAAM,UAAU,EAAE,6BAA6B,EAAE;EACjD,OAAO,UAAU,EAAE,iCAAiC,EAAE;AACxD","sourcesContent":["/* Toast notification */\n.toast-notification {\n  position: fixed;\n  bottom: 20px;\n  left: 50%;\n  transform: translateX(-50%);\n  background: rgba(0, 0, 0, 0.8);\n  color: white;\n  padding: 12px 24px;\n  border-radius: 8px;\n  font-size: 14px;\n  z-index: 1000;\n  animation: fadeInOut 3s ease;\n}\n\n@keyframes fadeInOut {\n  0% { opacity: 0; transform: translate(-50%, 20px); }\n  15% { opacity: 1; transform: translate(-50%, 0); }\n  85% { opacity: 1; transform: translate(-50%, 0); }\n  100% { opacity: 0; transform: translate(-50%, -20px); }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
