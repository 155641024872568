// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#events {
  padding: 20px;
  background: var(--cardBackground);
  border-radius: 8px;
}

.events-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.event-card {
  background: var(--cardBackground);
  border: 1px solid var(--grayBorder);
  border-radius: 8px;
  padding: 15px;
}

.event-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.event-title {
  font-weight: 600;
  font-size: 16px;
}

.event-date {
  color: var(--textColor);
  opacity: 0.7;
  font-size: 14px;
}

.event-description {
  color: var(--textColor);
  opacity: 0.9;
  font-size: 14px;
  line-height: 1.5;
}
`, "",{"version":3,"sources":["webpack://./src/styles/events.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iCAAiC;EACjC,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,iCAAiC;EACjC,mCAAmC;EACnC,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,uBAAuB;EACvB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,uBAAuB;EACvB,YAAY;EACZ,eAAe;EACf,gBAAgB;AAClB","sourcesContent":["#events {\n  padding: 20px;\n  background: var(--cardBackground);\n  border-radius: 8px;\n}\n\n.events-list {\n  display: flex;\n  flex-direction: column;\n  gap: 15px;\n}\n\n.event-card {\n  background: var(--cardBackground);\n  border: 1px solid var(--grayBorder);\n  border-radius: 8px;\n  padding: 15px;\n}\n\n.event-header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 10px;\n}\n\n.event-title {\n  font-weight: 600;\n  font-size: 16px;\n}\n\n.event-date {\n  color: var(--textColor);\n  opacity: 0.7;\n  font-size: 14px;\n}\n\n.event-description {\n  color: var(--textColor);\n  opacity: 0.9;\n  font-size: 14px;\n  line-height: 1.5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
