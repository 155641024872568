// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tags {
  display: flex;
  gap: 8px;
  margin-top: 8px;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.tag-button {
  background-color: var(--cardBackground);
  border: 1px solid var(--grayBorder);
  padding: 6px 12px;
  font-size: 13px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: var(--textColor);
}

.tag-button:hover {
  background-color: #9b9b9b;
}

.tag-chip {
  background: var(--cardBackground);
  border: 1px solid var(--grayBorder);
  color: var(--textColor);
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.tag-chip:hover {
  background: var(--hoverBackground);
}

.social-media {
  display: flex;
  gap: 8px;
  margin-top: 8px;
}

.social-media-button {
  display: inline-flex;
  align-items: center;
  padding: 8px 12px;
  font-size: 12px;
  background-color: #167194;
  color: white;
  text-decoration: none;
  border-radius: 20px;
  transition: background-color 0.3s ease;
}

.social-media-button i {
  margin-right: 5px;
}

.social-media-button:hover {
  background-color: #2980b9;
}
`, "",{"version":3,"sources":["webpack://./src/styles/tags.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,QAAQ;EACR,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,QAAQ;AACV;;AAEA;EACE,uCAAuC;EACvC,mCAAmC;EACnC,iBAAiB;EACjB,eAAe;EACf,kBAAkB;EAClB,eAAe;EACf,sCAAsC;EACtC,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,iCAAiC;EACjC,mCAAmC;EACnC,uBAAuB;EACvB,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,eAAe;EACf,oBAAoB;AACtB;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,aAAa;EACb,QAAQ;EACR,eAAe;AACjB;;AAEA;EACE,oBAAoB;EACpB,mBAAmB;EACnB,iBAAiB;EACjB,eAAe;EACf,yBAAyB;EACzB,YAAY;EACZ,qBAAqB;EACrB,mBAAmB;EACnB,sCAAsC;AACxC;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".tags {\n  display: flex;\n  gap: 8px;\n  margin-top: 8px;\n}\n\n.tags-container {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 8px;\n}\n\n.tag-button {\n  background-color: var(--cardBackground);\n  border: 1px solid var(--grayBorder);\n  padding: 6px 12px;\n  font-size: 13px;\n  border-radius: 5px;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n  color: var(--textColor);\n}\n\n.tag-button:hover {\n  background-color: #9b9b9b;\n}\n\n.tag-chip {\n  background: var(--cardBackground);\n  border: 1px solid var(--grayBorder);\n  color: var(--textColor);\n  padding: 4px 8px;\n  border-radius: 4px;\n  font-size: 12px;\n  font-weight: 500;\n  cursor: pointer;\n  transition: all 0.2s;\n}\n\n.tag-chip:hover {\n  background: var(--hoverBackground);\n}\n\n.social-media {\n  display: flex;\n  gap: 8px;\n  margin-top: 8px;\n}\n\n.social-media-button {\n  display: inline-flex;\n  align-items: center;\n  padding: 8px 12px;\n  font-size: 12px;\n  background-color: #167194;\n  color: white;\n  text-decoration: none;\n  border-radius: 20px;\n  transition: background-color 0.3s ease;\n}\n\n.social-media-button i {\n  margin-right: 5px;\n}\n\n.social-media-button:hover {\n  background-color: #2980b9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
