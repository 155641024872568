// src/PriceChart.js
import './styles/chart.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer
} from 'recharts';

const PriceChart = ({ symbol, change, preloadedData }) => {
    console.log('PriceChart rendered with:', { symbol, change, preloadedDataLength: preloadedData?.length });
    
    const [chartData, setChartData] = useState([]);
    const [timeRange, setTimeRange] = useState(1); // Default to 1 day
    const [activeButton, setActiveButton] = useState(1); // Default active button

    useEffect(() => {
        if (!symbol) return; // Early return if no symbol

        console.log('PriceChart useEffect triggered:', {
            symbol,
            timeRange,
            hasPreloadedData: !!preloadedData,
            preloadedDataLength: preloadedData?.length
        });

        if (preloadedData?.length > 0 && timeRange === 1) {
            console.log('Setting preloaded chart data:', {
                dataLength: preloadedData.length,
                firstItem: preloadedData[0],
                lastItem: preloadedData[preloadedData.length - 1]
            });
            setChartData(preloadedData);
            return;
        }

        const fetchPriceData = async () => {
            try {
                const interval = timeRange === 1 ? '1h' : timeRange === 7 ? '1d' : timeRange === 30 ? '1d' : '7d';
                console.log('Fetching price data:', { symbol, timeRange, interval });
                
                const response = await axios.post('/api/cmc-historical', {
                    symbol,
                    convert: 'USD',
                    time_start: new Date(Date.now() - timeRange * 24 * 60 * 60 * 1000).toISOString(),
                    time_end: new Date().toISOString(),
                    interval
                });

                let processedData;
                if (response.data?.data?.quotes) {
                    processedData = response.data.data.quotes.map(entry => ({
                        date: new Date(entry.timestamp).toISOString(),
                        price: entry.quote?.USD?.price || 0
                    }));
                } else if (response.data?.data?.[symbol]) {
                    const tokenData = response.data.data[symbol];
                    processedData = tokenData[0].quotes.map(entry => ({
                        date: new Date(entry.timestamp).toISOString(),
                        price: entry.quote?.USD?.price || 0
                    }));
                }

                if (processedData?.length > 0) {
                    console.log('Setting processed chart data:', {
                        dataLength: processedData.length,
                        firstItem: processedData[0],
                        lastItem: processedData[processedData.length - 1]
                    });
                    setChartData(processedData);
                } else {
                    console.error('Invalid or empty chart data:', {
                        hasData: !!response.data?.data,
                        dataKeys: Object.keys(response.data?.data || {}),
                        responseStructure: JSON.stringify(response.data, null, 2)
                    });
                    setChartData([]);
                }
            } catch (error) {
                console.error('Error fetching price data:', {
                    message: error.message,
                    response: error.response?.data,
                    symbol,
                    timeRange
                });
                setChartData([]);
            }
        };

        fetchPriceData();
    }, [symbol, timeRange, preloadedData]);
    
    // Return null if symbol is empty to prevent rendering
    if (!symbol) {
        return null;
    }

    const handleButtonClick = (range) => {
        console.log('Time range button clicked:', { newRange: range, previousRange: timeRange });
        setTimeRange(range);
        setActiveButton(range);
    };

    console.log('Rendering chart with data:', {
        hasData: chartData?.length > 0,
        dataLength: chartData?.length,
        firstItem: chartData?.[0],
        lastItem: chartData?.[chartData?.length - 1]
    });

    return (
        <div style={{ width: '100%', height: '100%' }}>            <div className="time-range-buttons">
                <button
                    className={`time-button ${activeButton === 1 ? 'active' : ''}`}
                    onClick={() => handleButtonClick(1)}
                >
                    24H
                </button>
                <button
                    className={`time-button ${activeButton === 7 ? 'active' : ''}`}
                    onClick={() => handleButtonClick(7)}
                >
                    7D
                </button>
                <button
                    className={`time-button ${activeButton === 30 ? 'active' : ''}`}
                    onClick={() => handleButtonClick(30)}
                >
                    30D
                </button>
            </div>
            <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                    data={chartData}
                    margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
                >
                    <defs>
                        <linearGradient id="colorPrice" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#53c6ff" stopOpacity={0.8}/>
                            <stop offset="95%" stopColor="#53c6ff" stopOpacity={0}/>
                        </linearGradient>
                    </defs>
                    <XAxis
                        dataKey="date"
                        tickFormatter={(time) => {
                            const date = new Date(time);
                            return timeRange === 1 
                                ? date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                                : date.toLocaleDateString([], { month: 'short', day: 'numeric' });
                        }}
                        dy={10}
                        style={{ fontSize: '11px' }}
                    />
                    <YAxis
                        domain={['auto', 'auto']}
                        tickFormatter={(value) => `$${value.toFixed(4)}`}
                        dx={-2}
                        width={65}
                        style={{ fontSize: '11px' }}
                    />
                    <Tooltip
                        formatter={(value) => [`$${value.toFixed(6)}`, 'Price']}
                        labelFormatter={(label) => {
                            const date = new Date(label);
                            return timeRange === 1
                                ? date.toLocaleString([], { 
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    month: 'short',
                                    day: 'numeric'
                                  })
                                : date.toLocaleDateString([], { 
                                    year: 'numeric',
                                    month: 'short',
                                    day: 'numeric'
                                  });
                        }}
                    />
                    <Area
                        type="monotone"
                        dataKey="price"
                        stroke="#53c6ff"
                        fillOpacity={1}
                        fill="url(#colorPrice)"
                    />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
};

export default PriceChart;