import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaLinkedin, FaXTwitter } from 'react-icons/fa6';
import '../styles/common.css';
import '../styles/team.css';

const TeamTab = ({ teamMembers }) => {
  const navigate = useNavigate();

  const handleMemberClick = (peopleId) => {
    navigate(`/people/${peopleId}`);
  };

  return (
    <div id="team">
      <div className="team-list">
        {teamMembers.map((member, index) => (
          <div 
            key={index} 
            className="team-member" 
            onClick={() => handleMemberClick(member.people_id)}
            style={{ cursor: 'pointer' }}
          >
            <div className="member-info">
              {member.head_img ? (
                <img src={member.head_img} alt={member.name} className="member-avatar" />
              ) : (
                <div className="avatar-fallback">
                  {member.name.charAt(0)}
                </div>
              )}
              <div className="member-details">
                <div className="member-name">{member.name}</div>
                <div className="member-position">{member.position}</div>
              </div>
              <div className="member-social" onClick={(e) => e.stopPropagation()}>
                {member.linkedin && (
                  <a href={member.linkedin} target="_blank" rel="noopener noreferrer" className="social-icon">
                    <FaLinkedin />
                  </a>
                )}
                {member.X && (
                  <a href={member.X} target="_blank" rel="noopener noreferrer" className="social-icon">
                    <FaXTwitter />
                  </a>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamTab;
