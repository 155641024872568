import React from 'react';
import '../styles/common.css';

const FundraisingTab = ({ assetData }) => {
  return (
    <div id="fundraising">
      <div className="total-funding">
        Total Funding: ${assetData.total_funding ? assetData.total_funding.toLocaleString() : 'N/A'}
      </div>
      <table className="fundraising-table">
        <thead>
          <tr>
            <th>Round</th>
            <th>Amount</th>
            <th>Valuation</th>
            <th>Date</th>
            <th>Investors</th>
          </tr>
        </thead>
        <tbody>
          {assetData.fundraising.items.map((item, index) => (
            <tr key={index}>
              <td>{item.rounds}</td>
              <td>${item.amount ? item.amount.toLocaleString() : 'N/A'}</td>
              <td>${item.valuation ? item.valuation.toLocaleString() : 'N/A'}</td>
              <td>{new Date(item.published_time).toLocaleDateString()}</td>
              <td>
                <div className="investor-list">
                  {item.invests.reduce((rows, investor, investIndex) => {
                    if (investIndex % 5 === 0) {
                      rows.push([]);
                    }
                    rows[rows.length - 1].push(
                      <div key={investIndex} className="investor-cell">
                        <img src={investor.logo} alt={investor.name} className="investor-logo" />
                        <span>{investor.name}</span>
                      </div>
                    );
                    return rows;
                  }, []).map((row, rowIndex) => (
                    <div key={rowIndex} className="investor-row">
                      {row}
                    </div>
                  ))}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FundraisingTab;
