import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import './styles/common.css';
import './styles/theme.css';
import './styles/layout.css';
import './styles/scrollbar.css';
import './styles/app-header.css';
import './styles/header.css';
import './styles/navigation.css';
import './styles/forms.css';
import './styles/logo.css';
import './styles/tags.css';
import './styles/investors.css';
import './styles/similar-projects.css';
import './styles/team.css';
import './styles/social.css';
import './styles/exchanges.css';
import './styles/chart.css';
import './styles/notifications.css';
import './styles/summary.css';
import './styles/fundraising.css';
import './styles/events.css';
import './styles/market.css';
import './styles/news.css';
import './loading.css';
import './wave.css';
import PriceChart from './PriceChart';
import TradingViewWidget from './TradingViewWidget';
import WebsiteIcon from './components/WebsiteIcon';
import RocketIcon from './components/RocketIcon';
import Logo from './components/logo';
import { FaLinkedin } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { FaMoon, FaSun } from 'react-icons/fa';
import MediumIcon from './components/medium.svg';
import DefillamaIcon from './components/defillama.svg';
import XIconDark from './components/x_dark.svg';
import XIconLight from './components/x_light.svg';
import BrowserIconDark from './components/browser_dark.svg';
import BrowserIconLight from './components/browser_light.svg';
import LinkedInDark from './components/linkedin_dark.svg';
import LinkedInLight from './components/linkedin_light.svg';
import MediumDark from './components/medium_dark.svg';
import MediumLight from './components/medium_light.svg';
import SummaryTab from './components/SummaryTab';
import FundraisingTab from './components/FundraisingTab';
import NewsTab from './components/NewsTab';
import MarketTab from './components/MarketTab';
import TeamTab from './components/TeamTab';
import SocialTab from './components/SocialTab';
import TradingViewTab from './components/TradingViewTab';
import SimilarProjectsTab from './components/SimilarProjectsTab';
import HomePage from './components/HomePage';
import Header from './components/Header';
import { ProjectSkeleton } from './components/Skeleton';

// Utility function to format large numbers
const formatNumber = (num) => {
  if (!num) return '0';
  
  // If num is an object (like from Coingecko), try to get the USD value
  if (typeof num === 'object' && num.usd) {
    num = num.usd;
  }
  
  // Convert to number if it's a string
  num = Number(num);
  
  // Check if conversion was successful
  if (isNaN(num)) return '0';
  
  if (num >= 1e9) {
    return (num / 1e9).toFixed(2) + 'B';
  } else if (num >= 1e6) {
    return (num / 1e6).toFixed(2) + 'M';
  } else if (num >= 1e3) {
    return (num / 1e3).toFixed(2) + 'K';
  }
  return num.toFixed(2);
};

function App() {
  const [activeTab, setActiveTab] = useState('Summary');
  const [assetData, setAssetData] = useState({});
  const [cmcData, setCmcData] = useState(null);
  const [cmcInfo, setCmcInfo] = useState(null);
  const [cgData, setCgData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [params] = useSearchParams();
  const [cmcMapping, setCmcMapping] = useState({});
  const [colorScheme, setColorScheme] = useState(() => {
    // Initialize theme once during component mount
    const savedTheme = localStorage.getItem('theme') || 'dark';
    document.body.setAttribute('data-theme', savedTheme);
    return savedTheme;
  });
  const [preloadedChartData, setPreloadedChartData] = useState(null);
  const [summaryLoaded, setSummaryLoaded] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [hasCmcData, setHasCmcData] = useState(false);
  const [dataSource, setDataSource] = useState('coingecko');

  // Theme management
  useEffect(() => {
    if (typeof window !== 'undefined' && window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.ready();
      const tgTheme = window.Telegram.WebApp.colorScheme;
      setColorScheme(tgTheme);
      document.body.setAttribute('data-theme', tgTheme);
      localStorage.setItem('theme', tgTheme);
      window.Telegram.WebApp.expand();
      window.Telegram.WebApp.setHeaderColor('#0B2A37');
    } else {
      console.warn('Telegram WebApp is not available');
    }
  }, []);

  const toggleTheme = () => {
    const newTheme = colorScheme === 'dark' ? 'light' : 'dark';
    setColorScheme(newTheme);
    document.body.setAttribute('data-theme', newTheme);
    localStorage.setItem('theme', newTheme);
  };

  // Load CMC data first
  useEffect(() => {
    const loadCmcData = async () => {
      try {
        const response = await fetch('/assets/cmc-data.csv');
        const text = await response.text();
        const mapping = {};
        const lines = text.split('\n');
        console.log('Number of lines:', lines.length);
        
        // Skip header
        lines.slice(1).forEach((line, index) => {
          if (!line.trim()) return;
          const parts = line.split(',');
          const rootdata_id = parts[1];
          const slug = parts[2]?.toLowerCase(); // CMC slugs are always lowercase
          if (rootdata_id && slug) {
            mapping[rootdata_id.trim()] = slug.trim();
          }
        });
        
        console.log('Setting CMC mapping with keys:', Object.keys(mapping).length);
        setCmcMapping(mapping);
        
        // If we're already trying to load data for a project, check if its CMC slug exists
        const projectId = params.get('tgWebAppStartParam') || '19';
        if (projectId) {
          const hasValidSlug = mapping[projectId];
          setHasCmcData(!!hasValidSlug);
          if (!hasValidSlug) {
            console.warn(`No CMC slug found for project ID: ${projectId}`);
          }
        }
      } catch (error) {
        console.error('Error loading CMC data:', error);
        setHasCmcData(false);
      }
    };
    loadCmcData();
  }, []);

  // Fetch asset data only after CMC mapping is loaded
  useEffect(() => {
    if (typeof window !== 'undefined' && window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.ready();
      setColorScheme(window.Telegram.WebApp.colorScheme);
      window.Telegram.WebApp.expand();
      window.Telegram.WebApp.setHeaderColor('#0B2A37');
    } else {
      console.warn('Telegram WebApp is not available');
    }

    const projectId = params.get('tgWebAppStartParam') || '19';
    
    const apiKey = process.env.REACT_APP_API_KEY;

    const fetchData = async () => {
      if (Object.keys(cmcMapping).length === 0) {
        console.log('Waiting for CMC mapping to be loaded...');
        return;
      }

      setLoading(true);
      setError(null);

      try {
        const headers = {
          'Content-Type': 'application/json',
          'language': 'en',
          'apikey': apiKey
        };

        const response = await axios.post('https://info.arcticdigital.info/api/details',
          { projectId: projectId },
          { headers }
        );

        if (response.data) {
          setAssetData(response.data);
          if (response.data.token_symbol) {
            try {
              const cmcSlug = cmcMapping[projectId];
              console.log('Looking up CMC slug:', { 
                projectId,
                cmcSlug, 
                mappingSize: Object.keys(cmcMapping).length,
                foundInMapping: projectId in cmcMapping,
                availableIds: Object.keys(cmcMapping).slice(0, 5) // Show first 5 IDs for debugging
              });

              if (!cmcSlug) {
                console.log('CMC slug not found, checking Coingecko mapping...');
                try {
                  const response = await axios.get('/assets/coingecko-data.csv');
                  const records = response.data.split('\n').map(line => {
                    const [url, rootdata_id, coingecko_id, token] = line.split(',');
                    return { rootdata_id, coingecko_id };
                  });
                  
                  const cgMapping = records.find(record => record.rootdata_id === projectId);
                  if (cgMapping) {
                    console.log('Found Coingecko mapping:', cgMapping);
                    const cgResponse = await axios.post('/api/coingecko-info', {
                      coinId: cgMapping.coingecko_id.toLowerCase()
                    });
                    
                    if (cgResponse.data) {
                      setCgData(cgResponse.data);
                      setDataSource('coingecko');
                      setHasCmcData(true);
                      setLoading(false);
                      setSummaryLoaded(true);
                      return;
                    }
                  }
                } catch (error) {
                  console.error('Error fetching Coingecko data:', error);
                }
                
                console.error('No data found in either CMC or Coingecko for project ID:', projectId);
                setLoading(false);
                setSummaryLoaded(true);
                setHasCmcData(false);
                return;
              }

              console.log('Fetching chart data with params:', {
                symbol: response.data.token_symbol,
                cmcSlug: cmcSlug,
                timeRange: {
                  start: new Date(Date.now() - 1 * 24 * 60 * 60 * 1000).toISOString(),
                  end: new Date().toISOString()
                }
              });

              const chartResponse = await axios.post('/api/cmc-historical', {
                symbol: response.data.token_symbol,
                cmcSlug: cmcSlug,
                convert: 'USD',
                time_start: new Date(Date.now() - 1 * 24 * 60 * 60 * 1000).toISOString(),
                time_end: new Date().toISOString(),
                interval: '1h'
              });
              
              // Get CMC market data
              const cmcResponse = await axios.post('/api/cmc-data', {
                symbol: response.data.token_symbol,
                cmcSlug: cmcSlug
              });
              
              if (cmcResponse.data) {
                console.log('CMC Data Response:', cmcResponse.data);
                // The data comes directly, not nested under data property
                setCmcData(cmcResponse.data);
              }

              // Get CMC info data
              const cmcInfoResponse = await axios.post('/api/cmc-info', {
                cmcSlug: cmcSlug
              });
              
              if (cmcInfoResponse.data) {
                // console.log('CMC Info Response:', cmcInfoResponse.data);
                setCmcInfo(cmcInfoResponse.data);
              }
              
              // console.log('Raw chart response:', JSON.stringify(chartResponse.data, null, 2));
              
              // Handle both data structures
              let processedData;
              const currentTime = new Date();
              const oneDayAgo = new Date(currentTime - 24 * 60 * 60 * 1000);

              if (chartResponse.data?.data?.quotes) {
                // Structure 1: direct quotes array
                processedData = chartResponse.data.data.quotes
                  .map(entry => {
                    const timestamp = new Date(entry.timestamp);
                    // Validate timestamp is within expected range
                    if (timestamp > oneDayAgo && timestamp <= currentTime) {
                      return {
                        date: timestamp.toISOString(),
                        price: entry.quote?.USD?.price || 0
                      };
                    }
                    return null;
                  })
                  .filter(entry => entry !== null);
              } else if (chartResponse.data?.data?.[response.data.token_symbol]) {
                // Structure 2: nested under token symbol
                const tokenData = chartResponse.data.data[response.data.token_symbol];
                processedData = tokenData[0].quotes
                  .map(entry => {
                    const timestamp = new Date(entry.timestamp);
                    // Validate timestamp is within expected range
                    if (timestamp > oneDayAgo && timestamp <= currentTime) {
                      return {
                        date: timestamp.toISOString(),
                        price: entry.quote?.USD?.price || 0
                      };
                    }
                    return null;
                  })
                  .filter(entry => entry !== null);
              }

              if (processedData?.length > 0) {
                console.log('Processed chart data (first 2 entries):', processedData.slice(0, 2));
                console.log('Total valid data points:', processedData.length);
                
                // Calculate 24h price change
                const latestPrice = processedData[processedData.length - 1].price;
                const earliestPrice = processedData[0].price;
                const priceChange = ((latestPrice - earliestPrice) / earliestPrice) * 100;
                
                console.log('24h Price Change Calculation:', {
                  latestPrice,
                  earliestPrice,
                  percentageChange: priceChange.toFixed(2)
                });
                
                setPreloadedChartData(processedData);
                // Update the asset data with the calculated price change
                setAssetData(prevData => ({
                  ...prevData,
                  price_change_24h: priceChange.toFixed(2)
                }));
              } else {
                console.error('Invalid chart data structure or no valid data points:', {
                  dataStructure: chartResponse.data,
                  processedDataLength: processedData?.length || 0
                });
                setPreloadedChartData([]);
              }
            } catch (chartError) {
              console.error('Error fetching chart data:', chartError);
              // Don't set error state here as it's not critical
            }
          }
          setSummaryLoaded(true);
        } else {
          setError('No data found for the provided asset name');
          setSummaryLoaded(true);
        }
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err.response?.data?.error || 'Error fetching data. Please try again.');
        setSummaryLoaded(true);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [params, cmcMapping]);

  useEffect(() => {
    console.log('colorScheme:', colorScheme);
  }, [colorScheme]);

  const openExternalLink = (url) => {
    console.log('Opening external link:', url);
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.openLink(url);
    } else {
      window.open(url, '_blank');
    }
  };

  const openTelegramLink = (url) => {
    console.log('Opening Telegram link:', url);
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.openTelegramLink(url);
    } else {
      window.open(url, '_blank');
    }
  }

  const requestContact = () => {
    console.log('Requesting Contact');
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.requestContact();
    } else {
      console.warn('Telegram WebApp is not available');
    }
  }

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      const contactRequestedHandler = (contact) => {
        console.log('Contact requested:', contact);
      };

      window.Telegram.WebApp.onEvent('contactRequested', contactRequestedHandler);

      return () => {
        window.Telegram.WebApp.offEvent('contactRequested', contactRequestedHandler);
      };
    }
  }, []);

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js';
      script.innerHTML = JSON.stringify({
        symbol: "OKX:ATHUSDT",
        width: 350,
        height: 220,
        locale: "en",
        dateRange: "12M",
        colorTheme: colorScheme === 'light' ? 'light' : 'dark',
        isTransparent: false,
        autosize: false,
        largeChartUrl: "",
        chartOnly: false,
        noTimeScale: false
      });

      const container = document.querySelector('.tradingview-widget-container__widget');
      if (container) {
        container.innerHTML = '';
        container.appendChild(script);
      }
    }
  }, [colorScheme]);

  const truncateAddress = (address) => {
    if (!address) return '';
    return `${address.slice(0, 4)}...${address.slice(-4)}`;
  };

  const showNotification = (message) => {
    setToastMessage(message);
    setShowToast(true);
    setTimeout(() => setShowToast(false), 3000);
  };

  return (
    <div className="app-container">
      {(!loading || !params.get('tgWebAppStartParam')) && (
        <Header 
          colorScheme={colorScheme} 
          toggleTheme={toggleTheme} 
          showSearch={true} 
        />
      )}
      <div className="content-container">
        <div className="content-wrapper">
          <div className="scroll-container">
            {!params.get('tgWebAppStartParam') ? (
              <HomePage />
            ) : (
              <>
                {(loading || !summaryLoaded) && (
                  <ProjectSkeleton />
                )}
                {!loading && summaryLoaded && error && (
                  <div className="error-container">
                    <p>{error}</p>
                    <button onClick={() => window.location.reload()} className="retry-button">
                      Retry
                    </button>
                  </div>
                )}
                {!loading && summaryLoaded && assetData && (
                  <div className="content-area">
                    <div id="details">
                      <div className="sticky-header">
                        <div className="asset-header no-rounded-corner">
                          <div className="asset-logo-wrapper">
                            <div className="underlay-circle"></div>
                            <img src={assetData.logo} alt={assetData.project_name} className="asset-logo" />
                          </div>
                          <div className="asset-info">
                            <h2>{assetData.project_name}</h2>
                            <p>{assetData.one_liner}</p>
                            <div className="social-media">
                              {Object.entries(assetData.social_media)
                                .filter(([platform]) => platform !== 'cmc')
                                .map(([platform, link]) => {
                                  if (!link) return null;
                                  
                                  const iconProps = {
                                    className: "social-media-icon",
                                    onClick: () => openExternalLink(link),
                                    style: { 
                                      fill: colorScheme === 'dark' ? '#ffffff' : '#0f232e',
                                      width: '24px',
                                      height: '24px',
                                      cursor: 'pointer'
                                    }
                                  };

                                  switch(platform) {
                                    case 'X':
                                      return <img 
                                        src={colorScheme === 'dark' ? XIconLight : XIconDark} 
                                        alt="X" 
                                        {...iconProps} 
                                        key={platform}
                                      />;
                                      case 'linkedin':
                                        return <img src={colorScheme === 'dark' ? LinkedInLight : LinkedInDark} alt="LinkedIn" {...iconProps} key={platform} />;  
                                      case 'website':
                                      return <img src={colorScheme === 'dark' ? BrowserIconLight : BrowserIconDark} alt="Browser" {...iconProps} key={platform} />;
                                    case 'medium':
                                      return <img src={colorScheme === 'dark' ? MediumLight : MediumDark} alt="Medium" {...iconProps} key={platform} />;
                                    case 'defillama':
                                      return <img src={DefillamaIcon} alt="DefiLlama" {...iconProps} key={platform} />;
                                    default:
                                      return null;
                                  }
                                })}
                            </div>
                          </div>
                        </div>

                        <div className="tabs continuous-bar">
                          <button
                            className={activeTab === 'Summary' ? 'active' : ''}
                            onClick={() => setActiveTab('Summary')}
                          >
                            Summary
                          </button>
                          {hasCmcData && (
                            <button
                              className={activeTab === 'Market' ? 'active' : ''}
                              onClick={() => setActiveTab('Market')}
                            >
                              Market
                            </button>
                          )}
                          {assetData.fundraising?.items?.length > 0 && (
                            <button
                              className={activeTab === 'Fundraising' ? 'active' : ''}
                              onClick={() => setActiveTab('Fundraising')}
                            >
                              Fundraising
                            </button>
                          )}
                          {assetData.reports?.length > 0 && (
                            <button
                              className={activeTab === 'News' ? 'active' : ''}
                              onClick={() => setActiveTab('News')}
                            >
                              News
                            </button>
                          )}
                          {assetData.team_members?.length > 0 && (
                            <button
                              className={activeTab === 'Team' ? 'active' : ''}
                              onClick={() => setActiveTab('Team')}
                            >
                              Team
                            </button>
                          )}
                          <button
                            className={activeTab === 'Social' ? 'active' : ''}
                            onClick={() => setActiveTab('Social')}
                          >
                            Social
                          </button>
                        </div>
                      </div>

                      {activeTab === 'Summary' && (
                        <SummaryTab assetData={assetData} />
                      )}
                      {activeTab === 'Fundraising' && (
                        <FundraisingTab assetData={assetData} />
                      )}
                      {activeTab === 'News' && assetData.reports?.length > 0 && (
                        <NewsTab reports={assetData.reports} />
                      )}
                      {activeTab === 'Team' && assetData.team_members?.length > 0 && (
                        <TeamTab teamMembers={assetData.team_members} />
                      )}
                      {activeTab === 'Social' && (
                        <SocialTab assetData={assetData} />
                      )}
                      {activeTab === 'Market' && hasCmcData && (
                        <MarketTab
                          cmcData={cmcData}
                          cmcInfo={cmcInfo}
                          cgData={cgData}
                          assetData={assetData}
                          preloadedChartData={preloadedChartData}
                          formatNumber={formatNumber}
                          truncateAddress={truncateAddress}
                          showNotification={showNotification}
                          dataSource={dataSource}
                        />
                      )}
                      {activeTab === 'TradingView' && (
                        <TradingViewTab symbol={assetData.token_symbol} />
                      )}

                      {activeTab === 'SimilarProjects' && (
                        <SimilarProjectsTab 
                          similarProjects={assetData.similar_project} 
                          openTelegramLink={openTelegramLink}
                        />
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {showToast && (
        <div className="toast-notification">
          {toastMessage}
        </div>
      )}
      {!loading && (
        <footer className="footer">
          <div className="footer-content">
            <p> <Logo /> Arctic Digital 2024 | Powered by <img src="https://www.rootdata.com/_nuxt/img/logo.7e97cac.png" alt="RootData" style={{ height: '28px', verticalAlign: 'middle' }} /></p>
          </div>
        </footer>
      )}
    </div>
  );
}

export default App;
