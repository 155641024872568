import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/common.css';
import '../../styles/similar-projects.css';

const InvestmentsTab = ({ investments }) => {
  const navigate = useNavigate();

  const handleInvestmentClick = (investment) => {
    // TODO: Add project ID to investment data from API
    console.log('Clicked investment:', investment);
  };

  return (
    <div id="similar-projects">
      <h3>Portfolio Companies:</h3>
      <ul>
        {investments.map((investment, index) => (
          <li key={index} className="similar-project">
            <img 
              src={investment.logo} 
              alt={investment.name} 
              className="similar-project-logo" 
              onClick={() => handleInvestmentClick(investment)}
              style={{ cursor: 'pointer' }}
            />
            <div>
              <strong>{investment.name}</strong>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default InvestmentsTab;
