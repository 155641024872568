// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#summary {
  padding: 20px;
  background: var(--cardBackground);
  border-radius: 8px;
}

.summary-header {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
}

.asset-info {
  display: flex;
  flex-direction: column;
}

.asset-info h2 {
  margin: 0;
  font-size: 22px;
  color: var(--textColor);
}

.summary-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.stat-card {
  background: var(--cardBackground);
  padding: 15px;
  border-radius: 8px;
  border: 1px solid var(--grayBorder);
}

.stat-label {
  color: var(--textColor);
  opacity: 0.7;
  font-size: 14px;
}

.stat-value {
  font-size: 18px;
  font-weight: 600;
  margin-top: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/summary.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iCAAiC;EACjC,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,SAAS;EACT,eAAe;EACf,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,2DAA2D;EAC3D,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,iCAAiC;EACjC,aAAa;EACb,kBAAkB;EAClB,mCAAmC;AACrC;;AAEA;EACE,uBAAuB;EACvB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":["#summary {\n  padding: 20px;\n  background: var(--cardBackground);\n  border-radius: 8px;\n}\n\n.summary-header {\n  display: flex;\n  align-items: center;\n  gap: 15px;\n  margin-bottom: 20px;\n}\n\n.asset-info {\n  display: flex;\n  flex-direction: column;\n}\n\n.asset-info h2 {\n  margin: 0;\n  font-size: 22px;\n  color: var(--textColor);\n}\n\n.summary-stats {\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));\n  gap: 20px;\n  margin-top: 20px;\n}\n\n.stat-card {\n  background: var(--cardBackground);\n  padding: 15px;\n  border-radius: 8px;\n  border: 1px solid var(--grayBorder);\n}\n\n.stat-label {\n  color: var(--textColor);\n  opacity: 0.7;\n  font-size: 14px;\n}\n\n.stat-value {\n  font-size: 18px;\n  font-weight: 600;\n  margin-top: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
