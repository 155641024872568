import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/common.css';
import '../styles/home.css';

const HomePage = () => {
  const [trendingProjects, setTrendingProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTrendingProjects = async () => {
      try {
        const response = await axios.get('/api/trending');
        setTrendingProjects(response.data.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to load trending projects');
        setLoading(false);
      }
    };

    fetchTrendingProjects();
  }, []);

  const openTelegramLink = (projectId) => {
    const url = `https://t.me/arctic_digital_info_bot/assetInfo?startapp=${projectId}`;
    window.Telegram.WebApp.openTelegramLink(url);
  };

  if (loading) {
    return (
      <div className="home-content">
        <div className="loading">Loading trending projects...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="home-content">
        <div className="error">{error}</div>
      </div>
    );
  }

  return (
    <div className="home-content">
      <h2 className="trending-title">Trending Projects</h2>
      <div className="trending-list">
        {trendingProjects.map((project) => (
          <div 
            key={project.project_id} 
            className="trending-item"
            onClick={() => openTelegramLink(project.project_id)}
          >
            <div className="project-logo">
              <img src={project.logo} alt={project.project_name} />
            </div>
            <div className="trending-info">
              <h3>{project.project_name}</h3>
              <div className="trending-tags">
                {project.tags.map((tag, index) => (
                  <span key={index} className="tag">{tag}</span>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomePage;
