// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#news {
  max-height: calc(100vh - 300px); /* Adjust this value based on your header/footer height */
  overflow-y: auto;
  padding: 1rem;
}

.news-table {
  width: 100%;
  border-collapse: collapse;
}

.news-table th {
  padding: 0.75rem;
  text-align: left;
  border-bottom: 1px solid var(--grayBorder);
  background-color: var(--headerBackground);
}

.news-table td {
  padding: 0.75rem;
  text-align: left;
  border-bottom: 1px solid var(--grayBorder);
}

.news-title {
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.news-source {
  font-size: 0.875rem;
  color: var(--textColor);
  opacity: 0.7;
}

.external-link-icon {
  margin-left: 0.25rem;
}
`, "",{"version":3,"sources":["webpack://./src/styles/news-tab.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B,EAAE,yDAAyD;EAC1F,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,0CAA0C;EAC1C,yCAAyC;AAC3C;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,0CAA0C;AAC5C;;AAEA;EACE,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":["#news {\n  max-height: calc(100vh - 300px); /* Adjust this value based on your header/footer height */\n  overflow-y: auto;\n  padding: 1rem;\n}\n\n.news-table {\n  width: 100%;\n  border-collapse: collapse;\n}\n\n.news-table th {\n  padding: 0.75rem;\n  text-align: left;\n  border-bottom: 1px solid var(--grayBorder);\n  background-color: var(--headerBackground);\n}\n\n.news-table td {\n  padding: 0.75rem;\n  text-align: left;\n  border-bottom: 1px solid var(--grayBorder);\n}\n\n.news-title {\n  font-weight: 500;\n  margin-bottom: 0.25rem;\n}\n\n.news-source {\n  font-size: 0.875rem;\n  color: var(--textColor);\n  opacity: 0.7;\n}\n\n.external-link-icon {\n  margin-left: 0.25rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
