// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Supported Exchanges styles */
.supported-exchanges {
  margin-top: 0rem;
  padding: 0.5rem;
  background: var(--cardBackground);
  border-radius: 12px;
}

.supported-exchanges h3 {
  margin-bottom: 1rem;
  font-size: 1.2rem;
  color: var(--textColor);
}

.exchanges-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 1rem;
}

.exchange-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem;
  background: var(--cardBackground);
  border-radius: 8px;
  transition: transform 0.2s;
}

.exchange-item:hover {
  transform: translateY(-2px);
}

.exchange-logo {
  width: 24px;
  height: 24px;
  border-radius: 4px;
}

.exchange-name {
  font-size: 0.9rem;
  color: var(--textColor);
}
`, "",{"version":3,"sources":["webpack://./src/styles/exchanges.css"],"names":[],"mappings":"AAAA,+BAA+B;AAC/B;EACE,gBAAgB;EAChB,eAAe;EACf,iCAAiC;EACjC,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;EACjB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,4DAA4D;EAC5D,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,gBAAgB;EAChB,iCAAiC;EACjC,kBAAkB;EAClB,0BAA0B;AAC5B;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,uBAAuB;AACzB","sourcesContent":["/* Supported Exchanges styles */\n.supported-exchanges {\n  margin-top: 0rem;\n  padding: 0.5rem;\n  background: var(--cardBackground);\n  border-radius: 12px;\n}\n\n.supported-exchanges h3 {\n  margin-bottom: 1rem;\n  font-size: 1.2rem;\n  color: var(--textColor);\n}\n\n.exchanges-grid {\n  display: grid;\n  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));\n  gap: 1rem;\n}\n\n.exchange-item {\n  display: flex;\n  align-items: center;\n  gap: 0.5rem;\n  padding: 0.75rem;\n  background: var(--cardBackground);\n  border-radius: 8px;\n  transition: transform 0.2s;\n}\n\n.exchange-item:hover {\n  transform: translateY(-2px);\n}\n\n.exchange-logo {\n  width: 24px;\n  height: 24px;\n  border-radius: 4px;\n}\n\n.exchange-name {\n  font-size: 0.9rem;\n  color: var(--textColor);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
