// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* News table styles */
.news-container {
  padding: 0 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.news-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  padding: 0 20px;
}

.news-table th,
.news-table td {
  padding: 12px 18px;
  text-align: left;
  border-bottom: 1px solid var(--grayBorder);
  font-size: 12px;
}

.news-table th {
  background-color: var(--headerBackground);
  font-weight: 600;
  color: var(--textColor);
}

.news-table td a {
  color: var(--accentColor);
  text-decoration: none;
  font-weight: 500;
  display: block;
}

.news-title {
  margin-bottom: 4px;
}

.news-source {
  font-size: 11px;
  color: var(--textColor);
  opacity: 0.7;
  display: flex;
  align-items: center;
  gap: 4px;
}

.news-table td:first-child {
  width: 100px;
  white-space: nowrap;
  vertical-align: top;
  padding-top: 14px;
}

.external-link-icon {
  width: 12px;
  height: 12px;
  margin-left: 4px;
  opacity: 0.7;
}`, "",{"version":3,"sources":["webpack://./src/styles/news.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB;EACE,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;;EAEE,kBAAkB;EAClB,gBAAgB;EAChB,0CAA0C;EAC1C,eAAe;AACjB;;AAEA;EACE,yCAAyC;EACzC,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;EACrB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,uBAAuB;EACvB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,YAAY;AACd","sourcesContent":["/* News table styles */\n.news-container {\n  padding: 0 20px;\n  max-width: 1200px;\n  margin: 0 auto;\n}\n\n.news-table {\n  width: 100%;\n  border-collapse: collapse;\n  margin-top: 20px;\n  padding: 0 20px;\n}\n\n.news-table th,\n.news-table td {\n  padding: 12px 18px;\n  text-align: left;\n  border-bottom: 1px solid var(--grayBorder);\n  font-size: 12px;\n}\n\n.news-table th {\n  background-color: var(--headerBackground);\n  font-weight: 600;\n  color: var(--textColor);\n}\n\n.news-table td a {\n  color: var(--accentColor);\n  text-decoration: none;\n  font-weight: 500;\n  display: block;\n}\n\n.news-title {\n  margin-bottom: 4px;\n}\n\n.news-source {\n  font-size: 11px;\n  color: var(--textColor);\n  opacity: 0.7;\n  display: flex;\n  align-items: center;\n  gap: 4px;\n}\n\n.news-table td:first-child {\n  width: 100px;\n  white-space: nowrap;\n  vertical-align: top;\n  padding-top: 14px;\n}\n\n.external-link-icon {\n  width: 12px;\n  height: 12px;\n  margin-left: 4px;\n  opacity: 0.7;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
