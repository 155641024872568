import React from 'react';
import PriceChart from '../PriceChart';
import '../styles/common.css';
import '../styles/market.css';
import '../styles/chart.css';
import '../styles/exchanges.css';

const MarketTab = ({ 
  cmcData, 
  cmcInfo, 
  cgData,
  assetData, 
  preloadedChartData, 
  formatNumber, 
  truncateAddress, 
  showNotification,
  dataSource = 'cmc'
}) => {
  // Helper function to get the correct data based on source
  const getData = () => {
    if (dataSource === 'coingecko' && cgData) {
      // Safely map platform data with null checks
      const platforms = cgData.platforms ? 
        Object.entries(cgData.platforms).map(([name, address]) => ({
          platform: { name: name || 'Unknown' },
          contract_address: address || ''
        })) : [];

      // Map tickers data with proper null checks
      const mappedTickers = (cgData.tickers || [])
        .filter(ticker => ticker && ticker.market && ticker.base && ticker.target)
        .map(ticker => ({
          market: {
            name: ticker.market.name || 'Unknown Exchange',
          },
          base: ticker.base,
          target: ticker.target,
          last: ticker.last,
          volume: ticker.converted_volume?.usd || ticker.volume,
          trade_url: ticker.trade_url
        }));

      return {
        price: cgData.market_data?.current_price?.usd,
        price_change_24h: cgData.market_data?.price_change_percentage_24h,
        volume_24h: cgData.market_data?.total_volume?.usd,
        volume_change_24h: cgData.market_data?.volume_change_24h,
        market_cap: cgData.market_data?.market_cap?.usd,
        fully_diluted_market_cap: cgData.market_data?.fully_diluted_valuation,
        circulating_supply: cgData.market_data?.circulating_supply,
        total_supply: cgData.market_data?.total_supply,
        max_supply: cgData.market_data?.max_supply,
        cmc_rank: cgData.market_cap_rank,
        contract_addresses: platforms,
        tags: cgData.categories?.map(category => ({ name: category })) || [],
        tickers: mappedTickers
      };
    }

    // CMC data format
    return {
      price: cmcData?.quote?.USD?.price,
      price_change_24h: cmcData?.quote?.USD?.percent_change_24h,
      volume_24h: cmcData?.quote?.USD?.volume_24h,
      volume_change_24h: cmcData?.quote?.USD?.volume_change_24h,
      market_cap: cmcData?.quote?.USD?.market_cap,
      fully_diluted_market_cap: cmcData?.quote?.USD?.fully_diluted_market_cap,
      circulating_supply: cmcData?.circulating_supply,
      total_supply: cmcData?.total_supply,
      max_supply: cmcData?.max_supply,
      cmc_rank: cmcData?.cmc_rank,
      contract_addresses: cmcInfo?.data?.[Object.keys(cmcInfo.data)[0]]?.contract_address || [],
      tags: cmcData?.tags || [],
      tickers: []
    };
  };

  const data = getData();
  console.log('Rendering market tab with data:', { dataSource, data });

  return (
    <div id="market">
      <div className="market-data-table">
        <table>
          <tbody>
            <tr>
              <td>Price</td>
              <td>
                <div className="price-display">
                  <span className="price-value">
                    ${Number(data.price || 0).toFixed(6)}
                  </span>
                  <span className={`price-change ${parseFloat(data.price_change_24h || 0) >= 0 ? 'positive' : 'negative'}`}>
                    {(data.price_change_24h || 0).toFixed(2)}%
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td>Trading Volume (24h)</td>
              <td>
                <div className="volume-display">
                  <span>${formatNumber(data.volume_24h)}</span>
                  <span className={`volume-change ${parseFloat(data.volume_change_24h || 0) >= 0 ? 'positive' : 'negative'}`}>
                    {(data.volume_change_24h || 0).toFixed(2)}%
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td>Market Cap</td>
              <td>
                <div className="market-cap-container">
                  <span>${formatNumber(data.market_cap)}</span>
                  {data.cmc_rank && (
                    <span className="rank-chip">#{data.cmc_rank}</span>
                  )}
                </div>
              </td>
            </tr>
            <tr>
              <td>Fully Diluted Valuation</td>
              <td>${formatNumber(data.fully_diluted_market_cap)}</td>
            </tr>
            <tr>
              <td>Circulating Supply</td>
              <td>{formatNumber(data.circulating_supply)} {assetData.token_symbol}</td>
            </tr>
            <tr>
              <td>Total Supply</td>
              <td>{formatNumber(data.total_supply)} {assetData.token_symbol}</td>
            </tr>
            <tr>
              <td>Max Supply</td>
              <td>{data.max_supply ? 
                 `${formatNumber(data.max_supply)} ${assetData.token_symbol}` : 
                 '∞'}</td>
            </tr>
            {data.contract_addresses?.map((contract, index) => (
              <tr key={index}>
                <td>{contract.platform.name}</td>
                <td>
                  <div className="contract-address">
                    <span className="address-text" title={contract.contract_address}>
                      {truncateAddress(contract.contract_address)}
                    </span>
                    <button 
                      className="copy-button"
                      onClick={() => {
                        navigator.clipboard.writeText(contract.contract_address);
                        showNotification('Contract address copied to clipboard');
                      }}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                        <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                      </svg>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {data.tags && data.tags.length > 0 && (
        <div className="tags-container">
          {data.tags.map((tag, index) => (
            <span key={index} className="tag-chip" title={tag.category}>
              {tag.name}
            </span>
          ))}
        </div>
      )}
      
      <div className="chart-container">
        <PriceChart
          symbol={assetData.token_symbol}
          change={data.price_change_24h}
          preloadedData={preloadedChartData}
        />
      </div>

      {(assetData.support_exchanges?.length > 0 || data.tickers?.length > 0) && (
        <div className="supported-exchanges">
          <h3>Supported Exchanges</h3>
          {dataSource === 'coingecko' && data.tickers?.length > 0 ? (
            <div className="exchanges-table">
              <table>
                <thead>
                  <tr>
                    <th>Exchange</th>
                    <th>Price</th>
                    <th>Volume (24h)</th>
                  </tr>
                </thead>
                <tbody>
                  {data.tickers.map((ticker, index) => (
                    <tr key={index}>
                      <td>
                        <a 
                          href={ticker.trade_url || `https://www.google.com/search?q=${encodeURIComponent(ticker.market.name + ' crypto exchange')}`} 
                          target="_blank" 
                          rel="noopener noreferrer"
                        >
                          {ticker.market.name}
                        </a>
                      </td>
                      <td>${formatNumber(ticker.last)}</td>
                      <td>${formatNumber(ticker.volume)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="exchanges-grid">
              {assetData.support_exchanges?.map((exchange, index) => (
                <div key={index} className="exchange-item">
                  <img 
                    src={exchange.exchange_logo} 
                    alt={exchange.exchange_name} 
                    className="exchange-logo"
                  />
                  <span className="exchange-name">{exchange.exchange_name}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MarketTab;
