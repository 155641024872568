import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaMoon, FaSun, FaSearch, FaTimes } from 'react-icons/fa';
import axios from 'axios';
import '../styles/common.css';
import '../styles/app-header.css';

const SkeletonSearchResult = () => (
  <div className="search-result-item skeleton">
    <div className="search-result-logo skeleton-circle"></div>
    <div className="search-result-info">
      <div className="search-result-name skeleton-text"></div>
      <div className="search-result-description skeleton-text"></div>
    </div>
  </div>
);

const Header = ({ colorScheme, toggleTheme, showSearch = false }) => {
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const searchTimeoutRef = useRef(null);
  const searchContainerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchContainerRef.current && !searchContainerRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSearch = async (query) => {
    if (!query.trim()) {
      setSearchResults([]);
      setShowDropdown(false);
      setIsLoading(false);
      return;
    }

    console.log('Starting search, setting loading state...');
    setIsLoading(true);
    setShowDropdown(true);

    try {
      console.log('Fetching search results...');
      const response = await axios.post('/api/search', { query: query.trim() });
      const results = response.data.data || [];
      console.log('Search complete, got results:', results.length);
      setSearchResults(results);
    } catch (error) {
      console.error('Search failed:', error);
      setSearchResults([]);
    } finally {
      console.log('Search finished, setting loading to false');
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    console.log('Input changed, current query:', query);

    // Clear previous timeout
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    // Set loading state immediately for better UX
    if (query.trim()) {
      console.log('Setting initial loading state...');
      setIsLoading(true);
      setShowDropdown(true);
    }

    // Debounce search
    searchTimeoutRef.current = setTimeout(() => {
      handleSearch(query);
    }, 300);
  };

  const openTelegramLink = (projectId) => {
    const url = `https://t.me/arctic_digital_info_bot/assetInfo?startapp=${projectId}`;
    window.Telegram.WebApp.openTelegramLink(url);
    setIsSearchActive(false);
    setSearchQuery('');
    setShowDropdown(false);
  };

  const toggleSearch = () => {
    setIsSearchActive(!isSearchActive);
    if (!isSearchActive) {
      setSearchQuery('');
      setSearchResults([]);
      setShowDropdown(false);
      setIsLoading(false);
    }
  };

  // Debug log for render
  console.log('Rendering Header with states:', {
    isLoading,
    showDropdown,
    resultsLength: searchResults.length,
    searchQuery
  });

  return (
    <header className="app-header">
      {!isSearchActive ? (
        <>
          <div className="header-logo">
            <a href="https://t.me/arctic_digital_info_bot/assetInfo" target="_blank" rel="noopener noreferrer">
              <img 
                src={colorScheme === 'dark' ? 'https://info.arcticdigital.info/arctic-logotype-white.png' : 'https://info.arcticdigital.info/arctic-logotype-dark.png'} 
                alt="Arctic Digital Logo" 
                className="header-logo-img"
              />
            </a>
          </div>
          <div className="header-actions">
            {showSearch && (
              <button onClick={toggleSearch} className="header-icon-btn">
                <FaSearch />
              </button>
            )}
            <button onClick={toggleTheme} className="theme-toggle-btn">
              {colorScheme === 'dark' ? <FaSun /> : <FaMoon />}
            </button>
          </div>
        </>
      ) : (
        <div className="header-search" ref={searchContainerRef}>
          <input
            type="text"
            className="header-search-input"
            placeholder="Search for a project..."
            value={searchQuery}
            onChange={handleInputChange}
            autoFocus
          />
          <button onClick={toggleSearch} className="header-icon-btn">
            <FaTimes />
          </button>
          {showDropdown && (
            <div className="search-dropdown">
              {isLoading ? (
                <div className="skeleton-container">
                  <SkeletonSearchResult />
                  <SkeletonSearchResult />
                  <SkeletonSearchResult />
                </div>
              ) : searchResults.length > 0 ? (
                searchResults.map((result) => (
                  <div 
                    key={result.id} 
                    className="search-result-item"
                    onClick={() => openTelegramLink(result.id)}
                  >
                    <img 
                      src={result.logo} 
                      alt={result.name}
                      className="search-result-logo"
                      onError={(e) => {
                        console.log('Image failed to load:', result.logo);
                        e.target.style.display = 'none';
                      }}
                    />
                    <div className="search-result-info">
                      <div className="search-result-name">{result.name || 'No name available'}</div>
                      <div className="search-result-description">{result.introduce || 'No description available'}</div>
                    </div>
                  </div>
                ))
              ) : searchQuery.trim() ? (
                <div className="no-results">No results found</div>
              ) : null}
            </div>
          )}
        </div>
      )}
    </header>
  );
};

export default Header;
