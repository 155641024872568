import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/common.css';

const SummaryTab = ({ assetData }) => {
  const navigate = useNavigate();

  const handleInvestorClick = (investor) => {
    if (investor.type === 1) {
      // Project type
      navigate(`/?tgWebAppStartParam=${investor.id}`);
    } else if (investor.type === 2) {
      // VC organization type
      navigate(`/org/${investor.id}`);
    } else if (investor.type === 3) {
      // Person type
      navigate(`/people/${investor.id}`);
    }
  };

  return (
    <div id="summary">
      <p>{assetData.description}</p>
      <div className="tags">
        {assetData.tags.map((tag, index) => (
          <button key={index} className="tag-button">{tag}</button>
        ))}
      </div>

      {assetData.investors.length > 0 && (
        <>
          <h3>Investors:</h3>
          <ul>
            {assetData.investors.map((investor, index) => (
              <li 
                key={index} 
                className="investor" 
                onClick={() => handleInvestorClick(investor)}
                style={{ cursor: 'pointer' }}
              >
                <img src={investor.logo} alt={investor.name} className="investor-logo" />
                <div>
                  <strong>{investor.name}</strong>
                  <span className="investor-type">
                    {investor.type === 1 ? 'Project' : 
                     investor.type === 2 ? 'Organization' : 
                     investor.type === 3 ? 'Individual' : ''}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default SummaryTab;
