import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FaLinkedin, FaXTwitter } from 'react-icons/fa6';
import axios from 'axios';
import Header from '../components/Header';
import { PeopleSkeleton } from '../components/Skeleton';
import '../styles/common.css';
import '../styles/people.css';

const PeoplePage = () => {
  const { peopleId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [personData, setPersonData] = useState(null);
  const [error, setError] = useState(null);
  const [colorScheme, setColorScheme] = useState(() => {
    return localStorage.getItem('theme') || 'light';
  });

  useEffect(() => {
    // Update body attribute when theme changes
    document.body.setAttribute('data-theme', colorScheme);
  }, [colorScheme]);

  const toggleTheme = () => {
    const newTheme = colorScheme === 'light' ? 'dark' : 'light';
    setColorScheme(newTheme);
    localStorage.setItem('theme', newTheme);
    document.body.setAttribute('data-theme', newTheme);
  };

  useEffect(() => {
    const fetchPersonData = async () => {
      try {
        const response = await axios.post('/api/people', { people_id: parseInt(peopleId) });
        setPersonData(response.data.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to load person data');
        setLoading(false);
      }
    };

    fetchPersonData();
  }, [peopleId]);

  if (loading) {
    return (
      <div className="app-container">
        <Header colorScheme={colorScheme} toggleTheme={toggleTheme} showSearch={true} />
        <div className="content-container">
          <div className="content-wrapper">
            <div className="scroll-container">
              <PeopleSkeleton />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="app-container">
        <Header colorScheme={colorScheme} toggleTheme={toggleTheme} showSearch={true} />
        <div className="error-container">
          <p>{error}</p>
          <button onClick={() => window.location.reload()} className="retry-button">
            Retry
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className={`app-container ${colorScheme}`}>
      <Header colorScheme={colorScheme} toggleTheme={toggleTheme} showSearch={true} />
      <div className="content-container">
        <div className="content-wrapper">
          <div className="scroll-container">
            <div className="content-area">
              <div className="sticky-header compact">
                <div className="person-header no-rounded-corner">
                  <div className="person-header-content">
                    <div className="person-logo-wrapper">
                      <div className="underlay-circle"></div>
                      <img src={personData.head_img} alt={personData.people_name} className="person-logo" />
                    </div>
                    <div className="person-info">
                      <div className="person-header-top">
                        <h2>{personData.people_name}</h2>
                        <button onClick={() => navigate(-1)} className="back-button">
                          Back to project
                        </button>
                      </div>
                      {personData.one_liner && <p className="one-liner">{personData.one_liner}</p>}
                      <div className="social-media">
                        {personData.linkedin && (
                          <a href={personData.linkedin} target="_blank" rel="noopener noreferrer" className="social-icon">
                            <FaLinkedin />
                          </a>
                        )}
                        {personData.X && (
                          <a href={personData.X} target="_blank" rel="noopener noreferrer" className="social-icon">
                            <FaXTwitter />
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="person-content">
                {personData.introduce && (
                  <div className="content-section">
                    <h3>Introduction</h3>
                    <p>{personData.introduce}</p>
                  </div>
                )}
                <div className="content-section">
                  <h3>Influence</h3>
                  <div className="influence-stats">
                    <div className="stat-box">
                      <span className="stat-label">Heat Score</span>
                      <span className="stat-value">{personData.heat}</span>
                      <span className="stat-rank">Rank #{personData.heat_rank}</span>
                    </div>
                    <div className="stat-box">
                      <span className="stat-label">Influence Score</span>
                      <span className="stat-value">{personData.influence}</span>
                      <span className="stat-rank">Rank #{personData.influence_rank}</span>
                    </div>
                  </div>
                </div>
                <div className="content-section">
                  <h3>Social Stats</h3>
                  <div className="social-stats-container">
                    <div className="stat-box">
                      <span className="stat-label">Followers</span>
                      <span className="stat-value">{personData.followers}</span>
                    </div>
                    <div className="stat-box">
                      <span className="stat-label">Following</span>
                      <span className="stat-value">{personData.following}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PeoplePage;
