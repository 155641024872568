// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Form styles */
form {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}

input {
  padding: 10px;
  font-size: 14px;
  width: 220px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: var(--darkBlue);
  color: var(--textColor);
}

button {
  padding: 10px 20px;
  font-size: 14px;
  background-color: var(--darkBlue);
  color: var(--textColor);
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #1C222E;
}

/* Error styles */
.error {
  color: #ff6b6b;
  font-size: 16px;
  margin-bottom: 20px;
}

.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  padding: 20px;
  text-align: center;
}

.retry-button {
  background-color: var(--accentColor);
  color: var(--darkBlue);
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  transition: opacity 0.2s;
}

.retry-button:hover {
  opacity: 0.9;
}
`, "",{"version":3,"sources":["webpack://./src/styles/forms.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,YAAY;EACZ,kBAAkB;EAClB,sBAAsB;EACtB,kBAAkB;EAClB,iCAAiC;EACjC,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,iCAAiC;EACjC,uBAAuB;EACvB,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA,iBAAiB;AACjB;EACE,cAAc;EACd,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;EACjB,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,oCAAoC;EACpC,sBAAsB;EACtB,kBAAkB;EAClB,kBAAkB;EAClB,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,wBAAwB;AAC1B;;AAEA;EACE,YAAY;AACd","sourcesContent":["/* Form styles */\nform {\n  display: flex;\n  justify-content: center;\n  margin-bottom: 25px;\n}\n\ninput {\n  padding: 10px;\n  font-size: 14px;\n  width: 220px;\n  margin-right: 10px;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n  background-color: var(--darkBlue);\n  color: var(--textColor);\n}\n\nbutton {\n  padding: 10px 20px;\n  font-size: 14px;\n  background-color: var(--darkBlue);\n  color: var(--textColor);\n  border: none;\n  cursor: pointer;\n  border-radius: 5px;\n  transition: background-color 0.3s ease;\n}\n\nbutton:hover {\n  background-color: #1C222E;\n}\n\n/* Error styles */\n.error {\n  color: #ff6b6b;\n  font-size: 16px;\n  margin-bottom: 20px;\n}\n\n.error-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  min-height: 200px;\n  padding: 20px;\n  text-align: center;\n}\n\n.retry-button {\n  background-color: var(--accentColor);\n  color: var(--darkBlue);\n  padding: 10px 20px;\n  border-radius: 5px;\n  border: none;\n  cursor: pointer;\n  font-weight: 500;\n  transition: opacity 0.2s;\n}\n\n.retry-button:hover {\n  opacity: 0.9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
