import React from 'react';
import '../styles/common.css';
import '../styles/social.css';

const SocialTab = ({ assetData }) => {
  return (
    <div id="social">
      <div className="social-stats">
        <div className="stat-item">
          <span className="stat-label">Influence Rank</span>
          <span className="stat-value">{assetData.influence_rank}</span>
        </div>
        <div className="stat-item">
          <span className="stat-label">Top Followers</span>
          <span className="stat-value">{assetData.top_followers}</span>
        </div>
        <div className="stat-item">
          <span className="stat-label">Followers</span>
          <span className="stat-value">{assetData.followers?.toLocaleString()}</span>
        </div>
        <div className="stat-item">
          <span className="stat-label">Following</span>
          <span className="stat-value">{assetData.following?.toLocaleString()}</span>
        </div>
      </div>
      <div className="social-links">
        {assetData.social_media?.website && (
          <a href={assetData.social_media.website} target="_blank" rel="noopener noreferrer" className="social-link">
            <i className="fas fa-globe"></i>
            <span>Website</span>
          </a>
        )}
        {assetData.social_media?.X && (
          <a href={assetData.social_media.X} target="_blank" rel="noopener noreferrer" className="social-link">
            <i className="fab fa-x-twitter"></i>
            <span>Twitter</span>
          </a>
        )}
        {assetData.social_media?.medium && (
          <a href={assetData.social_media.medium} target="_blank" rel="noopener noreferrer" className="social-link">
            <i className="fab fa-medium"></i>
            <span>Medium</span>
          </a>
        )}
      </div>
    </div>
  );
};

export default SocialTab;
