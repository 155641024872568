// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Tab styles */
.tabs {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  display: flex;
}

.tabs::-webkit-scrollbar {
  height: 4px;
}

.tabs::-webkit-scrollbar-track {
  background: transparent;
}

.tabs::-webkit-scrollbar-thumb {
  background: var(--grayBorder);
  border-radius: 4px;
}

.tabs button {
  color: var(--textColor);
  background: transparent;
  transition: all 0.3s;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 14px;
  position: relative;
  flex: 0 0 auto;
}

.tabs button:hover {
  color: var(--accentColor);
}

.tabs button.active {
  color: var(--accentColor);
  border-radius: 0;
  border-bottom: 2px solid var(--accentColor);
  background: transparent;
}

.tabs.continuous-bar {
  background-color: var(--cardBackground) !important;
  border-bottom: 1px solid var(--grayBorder);
  transition: background-color 0.3s;
  position: sticky;
  top: 0;
  z-index: 10;
}`, "",{"version":3,"sources":["webpack://./src/styles/navigation.css"],"names":[],"mappings":"AAAA,eAAe;AACf;EACE,WAAW;EACX,gBAAgB;EAChB,mBAAmB;EACnB,iCAAiC;EACjC,qBAAqB;EACrB,aAAa;AACf;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,6BAA6B;EAC7B,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;EACvB,uBAAuB;EACvB,oBAAoB;EACpB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,2CAA2C;EAC3C,uBAAuB;AACzB;;AAEA;EACE,kDAAkD;EAClD,0CAA0C;EAC1C,iCAAiC;EACjC,gBAAgB;EAChB,MAAM;EACN,WAAW;AACb","sourcesContent":["/* Tab styles */\n.tabs {\n  width: 100%;\n  overflow-x: auto;\n  white-space: nowrap;\n  -webkit-overflow-scrolling: touch;\n  scrollbar-width: thin;\n  display: flex;\n}\n\n.tabs::-webkit-scrollbar {\n  height: 4px;\n}\n\n.tabs::-webkit-scrollbar-track {\n  background: transparent;\n}\n\n.tabs::-webkit-scrollbar-thumb {\n  background: var(--grayBorder);\n  border-radius: 4px;\n}\n\n.tabs button {\n  color: var(--textColor);\n  background: transparent;\n  transition: all 0.3s;\n  border: none;\n  padding: 10px 20px;\n  cursor: pointer;\n  font-size: 14px;\n  position: relative;\n  flex: 0 0 auto;\n}\n\n.tabs button:hover {\n  color: var(--accentColor);\n}\n\n.tabs button.active {\n  color: var(--accentColor);\n  border-radius: 0;\n  border-bottom: 2px solid var(--accentColor);\n  background: transparent;\n}\n\n.tabs.continuous-bar {\n  background-color: var(--cardBackground) !important;\n  border-bottom: 1px solid var(--grayBorder);\n  transition: background-color 0.3s;\n  position: sticky;\n  top: 0;\n  z-index: 10;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
