import React from 'react';
import '../styles/common.css';
import '../styles/news-tab.css';

const NewsTab = ({ reports }) => {
  return (
    <div id="news">
      <table className="news-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Title</th>
          </tr>
        </thead>
        <tbody>
          {reports.map((report, index) => (
            <tr key={index}>
              <td>{new Date(report.time_east).toLocaleDateString()}</td>
              <td>
                <a href={report.url} target="_blank" rel="noopener noreferrer">
                  <div className="news-title">{report.title}</div>
                  <div className="news-source">[{report.site}] <span className="external-link-icon">↗</span></div>
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default NewsTab;
