// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Team styles */
.team-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

.team-member {
  background: var(--cardBackground);
  border-radius: 8px;
  padding: 0.75rem;
  transition: all 0.3s ease;
}

.team-member:hover {
  background: var(--hoverBackground);
}

.member-info {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.member-avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
}

.avatar-fallback {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #808a9d;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
}

.member-details {
  flex: 1 1;
}

.member-name {
  font-weight: 600;
  font-size: 1rem;
  color: var(--textColor);
  margin-bottom: 0.25rem;
}

.member-role {
  font-size: 0.875rem;
  color: var(--textColor);
  opacity: 0.7;
}

.member-social {
  margin-top: 0.5rem;
  display: flex;
  gap: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/styles/team.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,aAAa;AACf;;AAEA;EACE,iCAAiC;EACjC,kBAAkB;EAClB,gBAAgB;EAChB,yBAAyB;AAC3B;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,WAAW;AACb","sourcesContent":["/* Team styles */\n.team-list {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n  padding: 1rem;\n}\n\n.team-member {\n  background: var(--cardBackground);\n  border-radius: 8px;\n  padding: 0.75rem;\n  transition: all 0.3s ease;\n}\n\n.team-member:hover {\n  background: var(--hoverBackground);\n}\n\n.member-info {\n  display: flex;\n  align-items: center;\n  gap: 1rem;\n}\n\n.member-avatar {\n  width: 48px;\n  height: 48px;\n  border-radius: 50%;\n  object-fit: cover;\n}\n\n.avatar-fallback {\n  width: 48px;\n  height: 48px;\n  border-radius: 50%;\n  background-color: #808a9d;\n  color: white;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-size: 20px;\n  font-weight: 500;\n}\n\n.member-details {\n  flex: 1;\n}\n\n.member-name {\n  font-weight: 600;\n  font-size: 1rem;\n  color: var(--textColor);\n  margin-bottom: 0.25rem;\n}\n\n.member-role {\n  font-size: 0.875rem;\n  color: var(--textColor);\n  opacity: 0.7;\n}\n\n.member-social {\n  margin-top: 0.5rem;\n  display: flex;\n  gap: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
