// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Custom Scrollbar Styles */
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.4);
}

.tabs-container {
  overflow-x: auto;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: rgba(255, 255, 255, 0.4) transparent; /* For Firefox */
}

.tabs-container::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.tabs-container::-webkit-scrollbar-track {
  background: transparent;
}

.tabs-container::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.4);
  border-radius: 3px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/scrollbar.css"],"names":[],"mappings":"AAAA,4BAA4B;AAC5B;EACE,UAAU;EACV,WAAW;AACb;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,gBAAgB;EAChB,qBAAqB,EAAE,gBAAgB;EACvC,qDAAqD,EAAE,gBAAgB;AACzE;;AAEA;EACE,UAAU;EACV,WAAW;AACb;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,oCAAoC;EACpC,kBAAkB;AACpB","sourcesContent":["/* Custom Scrollbar Styles */\n::-webkit-scrollbar {\n  width: 3px;\n  height: 3px;\n}\n\n::-webkit-scrollbar-track {\n  background: transparent;\n}\n\n::-webkit-scrollbar-thumb {\n  background: rgba(255, 255, 255, 0.4);\n}\n\n.tabs-container {\n  overflow-x: auto;\n  scrollbar-width: thin; /* For Firefox */\n  scrollbar-color: rgba(255, 255, 255, 0.4) transparent; /* For Firefox */\n}\n\n.tabs-container::-webkit-scrollbar {\n  width: 3px;\n  height: 3px;\n}\n\n.tabs-container::-webkit-scrollbar-track {\n  background: transparent;\n}\n\n.tabs-container::-webkit-scrollbar-thumb {\n  background: rgba(255, 255, 255, 0.4);\n  border-radius: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
