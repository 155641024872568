// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.round-logo {
  width: 50px;
  height: 50px;
  margin-right: 15px;
  border-radius: 50%;
}

.round p {
  margin: 5px 0;
  color: var(--textColor);
  font-size: 14px;
}

.investor ul {
  padding-left: 0;
  margin-left: 0;
  list-style: none;
}

.investor li {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.investor-logo {
  width: 40px;
  height: 40px;
  margin-right: 8px;
  border-radius: 50%;
}

.investor a {
  text-decoration: none;
  color: #3498db;
  font-weight: 600;
  transition: color 0.3s ease;
}

.investor a:hover {
  color: #2980b9;
}

.investor {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 0;
}
`, "",{"version":3,"sources":["webpack://./src/styles/investors.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,cAAc;EACd,gBAAgB;EAChB,2BAA2B;AAC7B;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,cAAc;AAChB","sourcesContent":[".round-logo {\n  width: 50px;\n  height: 50px;\n  margin-right: 15px;\n  border-radius: 50%;\n}\n\n.round p {\n  margin: 5px 0;\n  color: var(--textColor);\n  font-size: 14px;\n}\n\n.investor ul {\n  padding-left: 0;\n  margin-left: 0;\n  list-style: none;\n}\n\n.investor li {\n  display: flex;\n  align-items: center;\n  margin-bottom: 8px;\n}\n\n.investor-logo {\n  width: 40px;\n  height: 40px;\n  margin-right: 8px;\n  border-radius: 50%;\n}\n\n.investor a {\n  text-decoration: none;\n  color: #3498db;\n  font-weight: 600;\n  transition: color 0.3s ease;\n}\n\n.investor a:hover {\n  color: #2980b9;\n}\n\n.investor {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  padding: 5px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
