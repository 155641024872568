import React from 'react';
import '../../styles/common.css';

const OrgSocialTab = ({ orgData }) => {
  return (
    <div className="social-stats">
      <div className="stat-section">
        <h3>Social Media Stats</h3>
        <div className="stats-grid">
          <div className="stat-box">
            <span className="stat-label">Followers</span>
            <span className="stat-value">{orgData.followers.toLocaleString()}</span>
          </div>
          <div className="stat-box">
            <span className="stat-label">Following</span>
            <span className="stat-value">{orgData.following.toLocaleString()}</span>
          </div>
          <div className="stat-box">
            <span className="stat-label">Top Followers</span>
            <span className="stat-value">{orgData.top_followers.toLocaleString()}</span>
          </div>
        </div>
      </div>

      <div className="stat-section">
        <h3>Influence Metrics</h3>
        <div className="stats-grid">
          <div className="stat-box">
            <span className="stat-label">Heat Score</span>
            <span className="stat-value">{orgData.heat}</span>
            <span className="stat-rank">Rank #{orgData.heat_rank}</span>
          </div>
          <div className="stat-box">
            <span className="stat-label">Influence Score</span>
            <span className="stat-value">{orgData.influence}</span>
            <span className="stat-rank">Rank #{orgData.influence_rank}</span>
          </div>
        </div>
      </div>

      {orgData.establishment_date && (
        <div className="stat-section">
          <h3>Organization Info</h3>
          <div className="stats-grid">
            <div className="stat-box">
              <span className="stat-label">Established</span>
              <span className="stat-value">{orgData.establishment_date}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrgSocialTab;
