import React from 'react';
import '../styles/common.css';
import '../styles/similar-projects.css';

const SimilarProjectsTab = ({ similarProjects, openTelegramLink }) => {
  return (
    <div id="similar-projects">
      <h3>Similar Projects:</h3>
      <ul>
        {similarProjects.map((project, index) => (
          <li key={index} className="similar-project">
            <img 
              onClick={() => openTelegramLink("https://t.me/arctic_digital_info_bot/assetInfo?startapp=" + project.project_name)} 
              src={project.logo} 
              alt={project.project_name} 
              className="similar-project-logo" 
            />
            <div>
              <strong>{project.project_name}</strong>: {project.brief_description}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SimilarProjectsTab;
