// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Asset header styles */
.asset-header {
  background: var(--cardBackground) !important;
  color: var(--textColor) !important;
  transition: background-color 0.3s, color 0.3s;
  padding: 20px;
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.asset-info {
  flex: 1 1;
  min-width: 0; /* Allows proper text overflow handling */
}

.asset-info h2 {
  font-size: 1.5rem;
  margin: 0 0 8px 0;
  line-height: 1.3;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 100%;
}

.asset-info p {
  font-size: 0.9rem;
  margin: 0 0 12px 0;
  line-height: 1.4;
  opacity: 0.9;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.asset-logo-wrapper {
  flex-shrink: 0;
  position: relative;
  width: 60px;
  height: 60px;
}

.asset-logo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
  position: relative;
  z-index: 2;
}

.social-media {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-top: 0.5rem;
}

.social-media-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: opacity 0.2s ease;
}

.social-media-icon:hover {
  opacity: 0.8;
}

.social-media-button {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/styles/header.css"],"names":[],"mappings":"AAAA,wBAAwB;AACxB;EACE,4CAA4C;EAC5C,kCAAkC;EAClC,6CAA6C;EAC7C,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,SAAS;AACX;;AAEA;EACE,SAAO;EACP,YAAY,EAAE,yCAAyC;AACzD;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,gBAAgB;EAChB,qBAAqB;EACrB,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,YAAY;EACZ,qBAAqB;EACrB,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,6BAA6B;AAC/B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;AACf","sourcesContent":["/* Asset header styles */\n.asset-header {\n  background: var(--cardBackground) !important;\n  color: var(--textColor) !important;\n  transition: background-color 0.3s, color 0.3s;\n  padding: 20px;\n  display: flex;\n  align-items: flex-start;\n  gap: 20px;\n}\n\n.asset-info {\n  flex: 1;\n  min-width: 0; /* Allows proper text overflow handling */\n}\n\n.asset-info h2 {\n  font-size: 1.5rem;\n  margin: 0 0 8px 0;\n  line-height: 1.3;\n  word-wrap: break-word;\n  overflow-wrap: break-word;\n  max-width: 100%;\n}\n\n.asset-info p {\n  font-size: 0.9rem;\n  margin: 0 0 12px 0;\n  line-height: 1.4;\n  opacity: 0.9;\n  word-wrap: break-word;\n  overflow-wrap: break-word;\n}\n\n.asset-logo-wrapper {\n  flex-shrink: 0;\n  position: relative;\n  width: 60px;\n  height: 60px;\n}\n\n.asset-logo {\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n  border-radius: 12px;\n  position: relative;\n  z-index: 2;\n}\n\n.social-media {\n  display: flex;\n  gap: 1rem;\n  align-items: center;\n  margin-top: 0.5rem;\n}\n\n.social-media-icon {\n  width: 24px;\n  height: 24px;\n  cursor: pointer;\n  transition: opacity 0.2s ease;\n}\n\n.social-media-icon:hover {\n  opacity: 0.8;\n}\n\n.social-media-button {\n  display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
